<template>
  <div class="home">
    <nav class="navbar navbar-expand-custom navbar-mainbg">
      <el-dropdown trigger="hover">
        <img
          src="https://img2.baidu.com/it/u=3847501523,3812026523&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
          alt=""
          style="
            width: 30px;
            height: 30px;
            cursor: pointer;
            border-radius: 50%;
            margin: 15px;
          "
        />
        <!-- @click="routerGo('/my')" -->
        <template #dropdown>
          <el-dropdown-menu>
            <div style="padding: 14px 6px 10px 6px; font-size: 14px">
              {{ $t("用户名") }}：{{ store.state.userInfo.username }}
            </div>
            <el-dropdown-item @click="updatePasswordDialog = true">{{
              $t("修改密码")
            }}</el-dropdown-item>
            <el-divider />
            <div style="padding: 14px 6px 10px 6px; font-size: 14px">
              {{ $t("切换系统语言") }}
            </div>
            <el-dropdown-item
              @click="changeLang('en')"
              :disabled="locale == 'en'"
              >🇺🇸English</el-dropdown-item
            >
            <el-dropdown-item
              @click="changeLang('tw')"
              :disabled="locale == 'tw'"
              >🇨🇳繁體中文</el-dropdown-item
            >
            <el-dropdown-item
              @click="changeLang('zh')"
              :disabled="locale == 'zh'"
              >🇨🇳简体中文</el-dropdown-item
            >
            <el-divider />
            <el-dropdown-item @click="back"
              ><i class="fa-solid fa-right-from-bracket"></i>{{ $t("退出") }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <button
        class="navbar-toggler"
        type="button"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars text-white"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <div class="hori-selector">
            <div class="left"></div>
            <div class="right"></div>
          </div>
          <!-- Dashboard -->
          <li
            class="nav-item"
            @click="routerGo('/dashboard')"
            :class="{ active: currentPath === '/dashboard' }"
          >
            <a class="nav-link" href="javascript:void(0);"
              ><i class="fas fa-tachometer-alt"></i>{{ $t("仪表盘") }}</a
            >
          </li>
          <li
            class="nav-item"
            @click="routerGo('/statisticalManage')"
            :class="{ active: currentPath === '/statisticalManage' }"
          >
            <a class="nav-link" href="javascript:void(0);"
              ><i class="fa-solid fa-square-poll-vertical"></i
              >{{ $t("统计管理") }}</a
            >
          </li>
          <li
            class="nav-item"
            @click="routerGo('/smsSend')"
            :class="{ active: currentPath === '/smsSend' }"
          >
            <a class="nav-link" href="javascript:void(0);"
              ><i class="fa-solid fa-paper-plane"></i>{{ $t("发送短信") }}</a
            >
          </li>
          <li
            class="nav-item"
            @click="routerGo('/sendDetail')"
            :class="{ active: currentPath === '/sendDetail' }"
          >
            <a class="nav-link" href="javascript:void(0);"
              ><i class="fa-solid fa-file-lines"></i>{{ $t("发送明细") }}</a
            >
          </li>
          <li
            class="nav-item"
            @click="routerGo('/smsUpLog')"
            :class="{ active: currentPath === '/smsUpLog' }"
          >
            <a class="nav-link" href="javascript:void(0);"
              ><i class="fa-solid fa-comment-dots"></i>{{ $t("上行回复") }}</a
            >
          </li>
          <li
            class="nav-item"
            @click="routerGo('/appManage')"
            :class="{ active: currentPath === '/appManage' }"
          >
            <a class="nav-link" href="javascript:void(0);"
              ><i class="fa-solid fa-cube"></i>{{ $t("应用管理") }}</a
            >
          </li>
          <li
            class="nav-item"
            @click="routerGo('/addressBook')"
            :class="{ active: currentPath === '/addressBook' }"
          >
            <a class="nav-link" href="javascript:void(0);"
              ><i class="fa-solid fa-address-book"></i>{{ $t("通讯录") }}</a
            >
          </li>
          <li
            class="nav-item"
            @click="routerGo('/my')"
            :class="{ active: currentPath === '/my' }"
            v-if="store.state.userInfo.payType == 1000"
          >
            <a class="nav-link" href="javascript:void(0);"
              ><i class="fa-solid fa-user"></i>我的</a
            >
          </li>
        </ul>
      </div>
    </nav>
    <div id="nav-occupy-tag" style="height: 80px"></div>

    <!-- 表单 -->
    <el-dialog
      v-model="updatePasswordDialog"
      :title="$t('修改密码')"
      width="340px"
      center
    >
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon>
        <el-form-item :label="$t('用户名')" prop="username">
          <el-input v-model.trim="ruleForm.username" disabled />
        </el-form-item>
        <el-form-item :label="$t('旧密码')" prop="password">
          <el-input
            v-model.trim="ruleForm.password"
            show-password
            :placeholder="$t('至少6位字符')"
          />
        </el-form-item>
        <el-form-item :label="$t('新密码')" prop="newPassword">
          <el-input
            v-model.trim="ruleForm.newPassword"
            show-password
            :placeholder="$t('至少6位字符')"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="updatePasswordDialog = false">{{
            $t("不用了")
          }}</el-button>
          <el-button type="primary" @click="updatePassword">{{
            $t("确定")
          }}</el-button>
        </span>
      </template>
    </el-dialog>

    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
<script setup>
import { onMounted, ref, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { changePassword,logout } from "@/http/api/login.js";
import { emitter } from "@/utils/bus";

// import  { FormInstance, FormRules } from 'element-plus'
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

const router = useRouter();
const route = useRoute();
const store = useStore();

// tab选中
var currentPath = "";
currentPath = route.path;
// 点击tab切换路由
const routerGo = (path) => {
  router.push({ path });
};
// 修改密码
const updatePasswordDialog = ref(false);
// const ruleFormRef = ref()
const ruleForm = reactive({
  username: store.state.userInfo.username || "",
  password: "",
  newPassword: "",
});
const rules = reactive({
  username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
  password: [
    { required: true, message: "", trigger: "blur" },
    // {
    //   pattern: /^.{6,}$/,
    //   message: "密码格式不正确",
    //   trigger: "blur",
    // },
  ],
  newPassword: [
    { required: true, message: "", trigger: "blur" },
    // {
    //   pattern: /^.{6,}$/,
    //   message: "密码格式不正确",
    //   trigger: "blur",
    // },
  ],
});

const updatePassword = async () => {
  if (ruleForm.password === "" || ruleForm.newPassword === "") {
    ElMessage({
      message: "请输入密码",
      type: "error",
    });
    return;
  }
  if (ruleForm.password === ruleForm.newPassword) {
    ElMessage({
      message: "新旧密码不能相同",
      type: "error",
    });
    return;
  }
  const res = await changePassword(ruleForm);
  if (res.code === 0) {
    // 新密码保存
    // let userInfo = { ...store.state.userInfo };
    // userInfo.password = ruleForm.newPassword;
    // store.commit("setUserInfo", userInfo);
    updatePasswordDialog.value = false;
    ElMessage({
      message: res.msg,
      type: "success",
    });
    back();
    // 清空新旧密码
    ruleForm.password = "";
    ruleForm.newPassword = "";
  } else {
    ElMessage({
      message: res.msg,
      type: "error",
    });
  }
};
// 退出登录
const back = async() => {
  await logout()
  store.commit("removeUserInfo");
  store.commit("removeToken");
  setTimeout(() => {
    router.push({ path: "/login" });
  }, 1000);
};

const changeLang = (lang) => {
  locale.value = lang;
  localStorage.setItem("lang", lang);
  emitter.emit("changeLang", lang);
  location.reload();
};

onMounted(() => {
  // ---------Responsive-navbar-active-animation-----------
  function test() {
    var tabsNewAnim = $("#navbarSupportedContent");
    var selectorNewAnim = $("#navbarSupportedContent").find("li").length;
    var activeItemNewAnim = tabsNewAnim.find(".active");
    var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
    var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
    var itemPosNewAnimTop = activeItemNewAnim.position();
    var itemPosNewAnimLeft = activeItemNewAnim.position();
    $(".hori-selector").css({
      top: itemPosNewAnimTop.top + "px",
      left: itemPosNewAnimLeft.left + "px",
      height: activeWidthNewAnimHeight + "px",
      width: activeWidthNewAnimWidth + "px",
    });
    $("#navbarSupportedContent").on("click", "li", function (e) {
      $("#navbarSupportedContent ul li").removeClass("active");
      $(this).addClass("active");
      var activeWidthNewAnimHeight = $(this).innerHeight();
      var activeWidthNewAnimWidth = $(this).innerWidth();
      var itemPosNewAnimTop = $(this).position();
      var itemPosNewAnimLeft = $(this).position();
      $(".hori-selector").css({
        top: itemPosNewAnimTop.top + "px",
        left: itemPosNewAnimLeft.left + "px",
        height: activeWidthNewAnimHeight + "px",
        width: activeWidthNewAnimWidth + "px",
      });
    });
  }
  $(document).ready(function () {
    setTimeout(function () {
      test();
    });
  });
  $(window).on("resize", function () {
    setTimeout(function () {
      test();
    }, 500);
  });
  $(".navbar-toggler").click(function () {
    $(".navbar-collapse").slideToggle(300);
    setTimeout(function () {
      test();
    });
  });

  // --------------add active class-on another-page move----------
  jQuery(document).ready(function ($) {
    // Get current path and find target link
    var path = window.location.pathname.split("/").pop();

    // Account for home page with empty path
    if (path === "") {
      path = "index.html";
    }

    var target = $('#navbarSupportedContent ul li a[href="' + path + '"]');
    // Add active class to target link
    target.parent().addClass("active");
  });
});
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto");

body {
  font-family: "Roboto", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

i {
  margin-right: 10px;
}

/*----------bootstrap-navbar-css------------*/
nav {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
}

.navbar-logo {
  // padding: 15px;
  color: #fff;
}

.navbar-mainbg {
  background-color: #5161ce;
  padding: 0px;
}

#navbarSupportedContent {
  overflow: hidden;
  position: relative;
}

#navbarSupportedContent ul {
  padding: 0px;
  margin: 0px;
}

#navbarSupportedContent ul li a i {
  margin-right: 10px;
}

#navbarSupportedContent li {
  list-style-type: none;
  float: left;
}

#navbarSupportedContent ul li a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  font-size: 15px;
  display: block;
  padding: 20px 20px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
}

#navbarSupportedContent > ul > li.active > a {
  color: #5161ce;
  background-color: transparent;
  transition: all 0.7s;
}

#navbarSupportedContent a:not(:only-child):after {
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 14px;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  transition: 0.5s;
}

#navbarSupportedContent .active > a:not(:only-child):after {
  transform: rotate(90deg);
}

.hori-selector {
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 0px;
  left: 0px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 10px;
}

.hori-selector .right,
.hori-selector .left {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #fff;
  bottom: 10px;
}

.hori-selector .right {
  right: -25px;
}

.hori-selector .left {
  left: -25px;
}

.hori-selector .right:before,
.hori-selector .left:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #5161ce;
}

.hori-selector .right:before {
  bottom: 0;
  right: -25px;
}

.hori-selector .left:before {
  bottom: 0;
  left: -25px;
}

@media (min-width: 992px) {
  .navbar-expand-custom {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-custom .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-custom .navbar-toggler {
    display: none;
  }

  .navbar-expand-custom .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

@media (max-width: 991px) {
  #navbarSupportedContent ul li a {
    padding: 12px 30px;
  }

  .hori-selector {
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .hori-selector .left,
  .hori-selector .right {
    right: 10px;
  }

  .hori-selector .left {
    top: -25px;
    left: auto;
  }

  .hori-selector .right {
    bottom: -25px;
  }

  .hori-selector .left:before {
    left: -25px;
    top: -25px;
  }

  .hori-selector .right:before {
    bottom: -25px;
    left: -25px;
  }
}
</style>
<style lang="scss">
.el-button {
  padding: 8px 15px !important;
}
</style>
